import React from 'react';
import classnames from '../../utils/classNames';
import styles from './styles.module.css';

const CardHeader = (props) => {
  const {
    title,
    subTitle,
    className,
    ...otherProps
  } = props;

  return (
    <div
      className={classnames([styles.header, className])}
      {...otherProps}
    >
      <h2>{title}</h2>
      {
        subTitle && (
          <p>{subTitle}</p>
        )
      }
    </div>
  );
};

export default CardHeader;
