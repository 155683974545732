import withAuth from '../../utils/withAuth';
import HeaderAuth from '../../utils/HeaderAuth';
import AxiosClient from '../../utils/AxiosClient';
import responseIntercepter from './responseIntercepter';

const headerAuth = new HeaderAuth(process.env.REACT_APP_AUTH_HEADER_NAME, 'Bearer');
const AuthClient = withAuth(headerAuth)(AxiosClient);

export const zhisuiClient = new AuthClient(
  {
    baseURL: process.env.REACT_APP_ZHISUI_ENDPOINT,
  },
  {
    response: responseIntercepter,
  },
);

export function authorize(token) {
  zhisuiClient.authorize(token);
}

export default {
  zhisuiClient,
};
