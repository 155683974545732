class HeaderAuth {
  constructor(header, prefix) {
    this._header = header;
    this._prefix = prefix ? `${prefix} ` : '';
  }

  authorize(client, token) {
    const { headers } = client.config() || {};

    return client.patchConfig({
      headers: {
        ...headers,
        [this._header]: `${this._prefix}${token}`,
      },
    });
  }
}

export default HeaderAuth;
