import React from 'react';

function Render(props) {
  const {
    component,
    as: Component = component,
    render,
    children,
    ...ownProps
  } = props;

  if (Component) {
    return (
      <Component {...ownProps}>
        {children}
      </Component>
    );
  }

  if (render) {
    return render(ownProps);
  }

  if (typeof children === 'function') {
    return children(ownProps);
  }

  return children;
}

export default Render;
