import {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { message } from 'antd';
import { ItemSyncContext } from '../../useItemSync';
import redcapApi from '../../../../apis/redcapApi';

function useGoodsClassifyList() {
  const {
    initialValues = {},
  } = useContext(ItemSyncContext);

  const initialValuesRef = useRef(initialValues);

  useEffect(() => {
    initialValuesRef.current = initialValues;
  }, [initialValues]);

  const [goodsClassifyList, setGoodsClassifyList] = useState([]);
  const [shippingTemplatesList, setShippingTemplatesList] = useState([]);
  const [countryList, setCountryList] = useState([]);

  const loadData = useCallback((selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    return redcapApi
      .fetchGoodsClassify({
        parent_cat_id: targetOption.value,
      })
      .then((res) => {
        const {
          data: {
            pdd_goods_cats_list: catsList,
          },
        } = res;

        targetOption.loading = false;
        targetOption.children = catsList.map((item) => {
          const {
            cat_id: value,
            cat_name: label,
          } = item;

          return {
            value,
            label,
            isLeaf: selectedOptions.length === 2,
          };
        });

        setGoodsClassifyList((goodsClassifyList) => [...goodsClassifyList]);
      })
      .catch((err) => {
        message.error(err.message || '获取商品分类失败');
        targetOption.loading = false;
      });
  }, [setGoodsClassifyList]);

  useEffect(() => {
    const {
      goods_info: [
        {
          cat_ids: catIds = [],
        } = {},
      ] = [],
    } = initialValuesRef.current;

    redcapApi
      .fetchGoodsClassify({
        parent_cat_id: 0,
      })
      .then((res) => {
        const {
          data: {
            pdd_goods_cats_list: catsList,
          },
        } = res;

        const list = catsList.map((item) => {
          const {
            cat_id: value,
            cat_name: label,
          } = item;

          return {
            value,
            label,
            isLeaf: false,
          };
        });

        return catIds.length > 0
          ? Promise.all(catIds.slice(0, 2).map((catId, index) => {
            return redcapApi
              .fetchGoodsClassify({
                parent_cat_id: catId,
              })
              .then((res) => {
                const {
                  data: {
                    pdd_goods_cats_list: catsList,
                  },
                } = res;

                return catsList.map((item) => {
                  const {
                    cat_id: value,
                    cat_name: label,
                  } = item;

                  return {
                    value,
                    label,
                    isLeaf: index >= 1,
                  };
                });
              });
          }))
            .then((resList) => {
              let currentOptions = list;

              for (let i = 0; i < 2; i += 1) {
                const currentTarget = currentOptions.find(({ value }) => value === catIds[i]);
                currentTarget.children = resList[i];
                currentOptions = currentTarget.children;
              }

              return list;
            })
          : Promise.resolve(list);
      })
      .then((list) => {
        setGoodsClassifyList(list);
      })
      .catch((err) => {
        message.error(err.message || '获取商品分类失败');
      });
  }, []);

  useEffect(() => {
    redcapApi
      .fetchShippingTemplates({
        page: 1,
        page_size: 20,
      })
      .then((res) => {
        setShippingTemplatesList(res.data.pdd_goods_logistics_template_list);
      })
      .catch((err) => {
        message.error(err.message || '获取运费模版失败');
      });
  }, []);

  useEffect(() => {
    redcapApi
      .fetchCountry()
      .then((res) => {
        setCountryList(res.data.pdd_country);
      })
      .catch((err) => {
        message.error(err.message || '获取国家失败');
      });
  }, []);

  return {
    goodsClassifyList,
    setGoodsClassifyList,
    shippingTemplatesList,
    setShippingTemplatesList,
    countryList,
    loadData,
  };
}

export default useGoodsClassifyList;
