import React from 'react';
import { css } from 'emotion/macro';
import { Form, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import useSmartField from './useSmartField';
import Render from '../Render';
import refine from '../../utils/refine';

const {
  Item: FormItem,
} = Form;

const styles = {
  label: css`
    display: inline-flex;
    align-items: center;
  `,
  help: css`
    margin-left: 4px;
    cursor: help;
  `,
};

export function simpleHelp(fieldProps, props) {
  const {
    tips: tipsProp,
  } = props;
  const {
    meta,
  } = fieldProps;

  const {
    touched,
    error,
  } = meta;

  if (touched && error) {
    if (typeof error === 'string') {
      return error;
    }

    const {
      __errors: errors,
    } = error;

    if ((errors)) {
      if (Array.isArray(errors)) {
        return errors.join(', ');
      }

      return Object.values(errors).join(', ');
    }
  }

  const tips = refine(tipsProp, fieldProps, props);

  if (tips) {
    return tips;
  }

  return undefined;
}

export function simpleValidateStatus(fieldProps) {
  const {
    meta,
  } = fieldProps;

  const {
    touched,
    error,
  } = meta;

  if (touched) {
    if (error) {
      if (typeof error === 'string' || error.__errors) {
        return 'error';
      }
    }

    return 'success';
  }

  return undefined;
}

function FormField(props) {
  const {
    component,
    as,
    render,
    name,
    label: labelProp,
    description: descriptionProp,
    tips,
    help: helpProp = simpleHelp,
    validateStatus: validateStatusProp = simpleValidateStatus,
    validate,
    children,
    ...otherProps
  } = props;

  const smartFieldProps = useSmartField(name, validate);
  const {
    field,
    meta,
    helpers,
  } = smartFieldProps;

  const label = refine(labelProp, smartFieldProps, props);
  const description = refine(descriptionProp, smartFieldProps, props);
  const help = refine(helpProp, smartFieldProps, props);
  const validateStatus = refine(validateStatusProp, smartFieldProps, props);
  const title = label && description ? (
    <div className={styles.label}>
      <span>{label}</span>
      <Tooltip title={description}>
        <QuestionCircleOutlined className={styles.help} />
      </Tooltip>
    </div>
  ) : label;

  return (
    <FormItem
      {...otherProps}
      label={title}
      help={help}
      validateStatus={validateStatus}
    >
      {Render({
        as,
        component,
        render,
        children,
        field,
        meta,
        helpers,
      })}
    </FormItem>
  );
}

export default FormField;
