function wait(time) {
  if (time !== undefined && time > 0) {
    return new Promise((resolve) => {
      const start = Date.now();

      setTimeout(() => {
        const end = Date.now();

        resolve(end - start);
      }, time);
    });
  }

  return Promise.resolve(0);
}

export default wait;
