const DUPLICATE_SLASH_REG = /\/{2,}/;
const DUPLICATE_SLASH_REPLACE_REG = new RegExp(DUPLICATE_SLASH_REG, 'g');
const TRAILING_SLASH_REG = /\/+$/;

function normalizeEntry(entry, trailingSlash) {
  let normalizedEntry;

  const hasTrailingSlash = TRAILING_SLASH_REG.test(entry);

  if (trailingSlash && !hasTrailingSlash) {
    normalizedEntry = `${entry}/`;
  } else if (!trailingSlash && hasTrailingSlash) {
    normalizedEntry = entry.replace(TRAILING_SLASH_REG, '');
  } else {
    normalizedEntry = entry;
  }

  return DUPLICATE_SLASH_REG.test(normalizedEntry)
    ? normalizedEntry.replace(DUPLICATE_SLASH_REPLACE_REG, '/')
    : normalizedEntry;
}

export function simpleHandleResponseResolve(response) {
  return Promise.resolve(response.data);
}

export function simpleHandleResponseReject(error) {
  Object.assign(error, {
    isApiError: true,
  });

  return Promise.reject(error);
}

class Restful {
  constructor(options = {}) {
    const {
      client,
      trailingSlash = false,
      entry = '/',
      onResponseResolve = simpleHandleResponseResolve,
      onResponseReject = simpleHandleResponseReject,
    } = options;

    this._client = client;
    this._trailingSlash = trailingSlash;
    this._entry = normalizeEntry(entry, trailingSlash);
    this._onResponseResolve = onResponseResolve;
    this._onResponseReject = onResponseReject;
  }

  client(client) {
    if (client === undefined) {
      return this._client;
    }

    this._client = client;

    return this;
  }

  trailingSlash(trailingSlash) {
    if (trailingSlash === undefined) {
      return this._trailingSlash;
    }

    this._trailingSlash = trailingSlash;

    return this;
  }

  entry(entry) {
    if (entry === undefined) {
      return this._entry;
    }

    this._entry = entry;

    return this;
  }

  service(entry) {
    return new Restful({
      client: this._client,
      trailingSlash: this._trailingSlash,
      onResponseResolve: this._onResponseResolve,
      onResponseReject: this._onResponseReject,
      entry,
    });
  }

  custom(path) {
    return this.service(`${this._entry}/${path}`);
  }

  all(path) {
    return this.custom(path);
  }

  one(path, id) {
    return this.custom(id === undefined ? String(path) : `${path}/${id}`);
  }

  request(config) {
    return new Promise((resolve) => {
      resolve(this._client.request({
        ...config,
        url: this._entry,
      }));
    })
      .then(this._onResponseResolve)
      .catch(this._onResponseReject);
  }

  get(config) {
    return this.request({
      ...config,
      method: 'get',
    });
  }

  post(data, config) {
    return this.request({
      ...config,
      method: 'post',
      data,
    });
  }

  delete(config) {
    return this.request({
      ...config,
      method: 'delete',
    });
  }

  put(data, config) {
    return this.request({
      ...config,
      method: 'put',
      data,
    });
  }

  patch(data, config) {
    return this.request({
      ...config,
      method: 'patch',
      data,
    });
  }

  head(config) {
    return this.request({
      ...config,
      method: 'head',
    });
  }

  options(config) {
    return this.request({
      ...config,
      method: 'options',
    });
  }

  list(config) {
    return this.get(config);
  }

  fetch(id, config) {
    return this
      .one(id)
      .get(config);
  }

  create(data, config) {
    return this
      .post(data, config);
  }

  update(id, data, config) {
    return this
      .one(id)
      .put(data, config);
  }

  partialUpdate(id, data, config) {
    return this
      .one(id)
      .patch(data, config);
  }

  destroy(id, config) {
    return this
      .one(id)
      .delete(config);
  }
}

export default Restful;
