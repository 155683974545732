import React, {
  useState,
  useRef,
  useContext,
  useCallback,
} from 'react';
import {
  Card,
  Form,
  Button,
  message,
} from 'antd';
import { cx, css } from 'emotion';
import 'moment/locale/zh-cn';
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import ItemSyncForm from './ItemSyncForm';
import { ItemSyncContext } from '../../useItemSync';
import redcap from '../../../../apis/redcapApi';
import successImage from './assets/success.png';
import guideImage from './assets/guide.png';

const styles = {
  sync: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 100%;
  `,
  guide: css`
    width: auto;
    height: 101.5%;
    margin-left: -1%;
  `,
  cardWrapper: css`
    flex: 1;
    height: 100%;
  `,
  card: css`
    height: 100%;
    overflow: auto;
    border-radius: 8px;
  `,
  form: css`
    max-width: 800px;
    .ant-form-item {
      display: flex;
      justify-content: space-between;
    }
    .ant-form-item-label {
      flex: 0 130px;
    }
    .ant-form-item-control {
      flex: 1;
    }
  `,
  result: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: all .3s;
    z-index: 1;
    > div {
      height: 100%;
    }
    .ant-card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      > * {
        margin-bottom: 20px;
      }
    }
  `,
  resultHide: css`
    top: 100%;
    opacity: 0;
  `,
  syncCount: css`
    line-height: 30px;
    padding: 10px 48px;
    font-weight: 600;
    border-radius: 4px;
    background-color: #F0F7FF;
    > span {
      display: inline-block;
      vertical-align: -2px;
      padding: 0 10px;
      font-size: 30px;
      color: #FA6400;
    }
  `,
  adjust: css`
    display: flex;
    align-items: center;
    width: 800px;
    height: 115px;
    padding: 0 50px;
    border-radius: 4px;
    background-color: #FFF6E5;
    > span {
      flex: 0 0 52px;
      margin-right: 20px;
      font-size: 52px;
      color: #FFAB66;
    }
    > div {
      flex: 1 0 0;
      margin-top: 10px;
      > h3 span {
        color: #FA6400;
      }
    }
    > p {
      color: #84868C;
      margin-bottom: 0;
    }
  `,
};

const {
  Item: FormItem,
} = Form;

const ItemSync = () => {
  const {
    initialValues,
    setInitialValues,
  } = useContext(ItemSyncContext);

  const [isResultShow, setIsResultShow] = useState(false);

  const [resultErrorText, setResultErrorText] = useState();

  const [syncItemCount, setSyncItemCount] = useState(0);

  const containerRef = useRef(null);

  const handleSubmit = useCallback((values) => {
    const {
      goods_info: goodsInfo,
      sensitive_words: sensitiveWords,
      goods_multi_price_increase: goodsMultiPriceIncrease,
      goods_price_increase: goodsPriceIncrease,
    } = values;

    const filteredGoodsInfo = goodsInfo.filter(({ goods_url: goodsUrl }) => goodsUrl);

    return redcap
      .submitItemSync({
        ...values,
        goods_info: filteredGoodsInfo,
        sensitive_words: sensitiveWords.filter((word) => word),
        goods_multi_price_increase: goodsMultiPriceIncrease,
        goods_price_increase: goodsPriceIncrease,
      })
      .then((res) => {
        const {
          code,
          data,
        } = res;

        const {
          err_msg: errMsg,
        } = data || {};

        if (code !== 0) {
          return Promise.reject(new Error(errMsg));
        }

        setInitialValues(undefined);
        setIsResultShow(true);
        setSyncItemCount(filteredGoodsInfo.length);
        if (errMsg) {
          const errMsgs = errMsg.split(',');
          if (errMsgs.length <= 1) {
            setResultErrorText(errMsg);
          } else {
            const indexTextMap = ['一', '二', '三', '四', '五'];
            setResultErrorText(errMsgs.map((msg, index) => `第${indexTextMap[index] || index}件商品${msg}`).join('，'));
          }
        } else {
          setResultErrorText(errMsg);
        }

        return null;
      })
      .catch((err) => {
        const {
          message: errMessage,
        } = err;

        if (errMessage === 'Network Error' || !errMessage) {
          message.error('同步失败…请稍后再试哦…');
        } else {
          const errMessages = errMessage.split(':').map((msg) => msg.trim());
          message.error(errMessages[1] || errMessages[0]);
        }
      });
  }, [setInitialValues]);

  return (
    <div
      className={styles.sync}
      ref={containerRef}
    >
      <img
        className={styles.guide}
        src={guideImage}
        alt="操作引导"
      />
      <div className={styles.cardWrapper}>
        <Card
          className={styles.card}
          title="商品同步"
        >
          <ItemSyncForm
            key={`${isResultShow}`}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {(form, formProps) => {
              const {
                isSubmitting,
                handleSubmit,
              } = formProps;
              return (
                <>
                  {form}
                  <Form
                    className={styles.form}
                    colon={false}
                  >
                    <FormItem
                      label=" "
                    >
                      <Button
                        type="primary"
                        loading={isSubmitting}
                        onClick={handleSubmit}
                      >
                        立即同步
                      </Button>
                    </FormItem>
                  </Form>
                </>
              );
            }}
          </ItemSyncForm>
        </Card>
      </div>
      <div className={cx(styles.result, !isResultShow && styles.resultHide)}>
        <Card>
          <img
            src={successImage}
            alt="同步成功"
            width={resultErrorText ? 95 : 240}
          />
          <h3>YEAH！同步成功啦！</h3>
          <div className={styles.syncCount}>
            本次同步
            <span>{syncItemCount}</span>
            件商品
          </div>
          {
            resultErrorText ? (
              <div className={styles.adjust}>
                <ExclamationCircleOutlined />
                <div>
                  <h3>
                    系统已自动为您将「本批商品的团购价」调整为：
                    <span>{resultErrorText}</span>
                  </h3>
                  <p>(团购价调整幅度根据拼多多规则，不能超过同步商品原价的+8%，超过将自动更改为8%)</p>
                </div>
              </div>
            ) : null
          }
          <Button
            type="primary"
            onClick={() => setIsResultShow(false)}
          >
            返回
          </Button>
        </Card>
      </div>
    </div>
  );
};

export default ItemSync;
