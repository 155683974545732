import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Empty } from 'antd';
import { useLocalStorage } from 'react-use';
import useAuth, { STORAGE_TOKEN_NAME } from './hooks/useAuth';
import styles from './styles.module.css';

const Forbidden = () => {
  const [, setStorageAuthToken] = useLocalStorage(STORAGE_TOKEN_NAME);

  useEffect(() => {
    setStorageAuthToken(null);
    // eslint-disable-next-line
  }, []);

  const {
    authed,
  } = useAuth();

  if (authed) {
    return <Redirect to="/" />;
  }

  return (
    <Empty
      className={styles.empty}
      description="未授权的访问"
    />
  );
};

export default Forbidden;
