/* eslint-disable react-hooks/rules-of-hooks */

import React, { useState } from 'react';
import {
  Formik,
  Field,
  FieldArray,
} from 'formik';
import {
  Form,
  Input,
  Cascader,
  Button,
  Select,
  Radio,
  DatePicker,
  InputNumber,
} from 'antd';
import { PlusOutlined, CloseCircleFilled } from '@ant-design/icons';
import { cx, css } from 'emotion';
import moment from 'moment';
import useFormData from './useFormData';
import FieldItem from '../../../../components/FieldItem';
import useSmartField from '../../../../components/FieldItem/useSmartField';

const styles = {
  form: css`
    max-width: 800px;
    .ant-form-item {
      display: flex;
      justify-content: space-between;
    }
    .ant-form-item-label {
      flex: 0 130px;
    }
    .ant-form-item-control {
      flex: 1;
    }
  `,
  item: css`
    display: flex;
    margin-bottom: 10px;
    > * {
      margin-right: 10px;
    }
    .ant-input {
      flex: 1;
    }
    .ant-select {
      flex: 0 156px;
    }
    > .anticon {
      flex: 0 20px;
      line-height: 32px;
      cursor: pointer;
    }
  `,
  select: css`
    width: 230px !important;
  `,
  selectLong: css`
    width: 300px !important;
  `,
  datePicker: css`
    width: 230px !important;
  `,
  yellowWrapper: css`
    margin-bottom: 7px;
    padding: 17px 24px;
    background-color: #FFF8E4;
    border-radius: 4px;
  `,
  inputCom: css`
    margin-bottom: 0;
    > span {
      font-size: 14px;
      color: #333;
    }
  `,
  mr10: css`
    margin-right: 10px;
  `,
  light: css`
    line-height: 20px;
    font-size: 12px;
    color: #999;
  `,
  icon: css`
    color: rgba(0, 0, 0, .13);
    cursor: pointer;
  `,
  disabled: css`
    cursor: not-allowed !important;
  `,
  groupSelect: css`
    width: 78px !important;
    vertical-align: middle;
  `,
  inputNumber: css`
    width: 106px !important;
    margin: 0 10px;
    vertical-align: middle;
  `,
  blue: css`
    color: #316AFF;
  `,
};

const {
  Option,
} = Select;
const {
  Group: RadioGroup,
} = Radio;
const {
  TextArea,
} = Input;
const {
  Item: FormItem,
} = Form;

function validate(values) {
  const errors = {};

  const {
    goods_info: goodsInfo = [],
    cost_template_id: costTemplateId,
    goods_type: goodsType,
    country_id: countryId,
    goods_multi_price_increase: goodsMultiPriceIncrease,
    goods_price_increase: goodsPriceIncrease,
  } = values;

  if (goodsInfo.every(({ goods_url: goodsUrl, cat_ids: catIds }) => !goodsUrl || !catIds.length)) {
    errors.goods_info = '请至少粘贴一个链接和商品分类';
  }
  if (!costTemplateId) {
    errors.cost_template_id = '请选择运费模版';
  }
  if ((goodsType === '2' || goodsType === '3') && !countryId) {
    errors.country_id = '请选择国家';
  }
  if (!goodsMultiPriceIncrease && goodsMultiPriceIncrease !== 0) {
    errors.goods_multi_price_increase = '请输入调整团购价';
  }
  if (!goodsPriceIncrease && goodsPriceIncrease !== 0) {
    errors.goods_price_increase = '请输入减少的金额';
  }

  return errors;
}

const defaultPreSaleTime = moment().endOf('days').format('X');

const defaultInitialValues = {
  goods_info: [
    {
      goods_url: '',
      cat_ids: [],
    },
    {
      goods_url: '',
      cat_ids: [],
    },
    {
      goods_url: '',
      cat_ids: [],
    },
  ],
  cost_template_id: undefined,
  goods_type: '1',
  country_id: 0,
  second_hand: false,
  is_pre_sale: false,
  pre_sale_time: defaultPreSaleTime,
  shipment_limit_second: 48 * 3600,
  is_refundable: true,
  is_folt: true,
  goods_multi_price_increase: 0,
  goods_price_increase: 100,
  sensitive_words: [],
};

function ItemSyncForm(props) {
  const {
    initialValues = defaultInitialValues,
    children,
    ...otherProps
  } = props;

  const {
    goods_multi_price_increase: goodsMultiPriceIncrease,
  } = initialValues;

  const {
    goodsClassifyList,
    shippingTemplatesList,
    loadData,
    countryList,
  } = useFormData();

  const [groupSign, setGroupSign] = useState(goodsMultiPriceIncrease < 0 ? -1 : 1);

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      {...otherProps}
    >
      {(formProps) => {
        const {
          values: {
            goods_info: goodsInfo,
            goods_type: goodsType,
            is_pre_sale: isPreSale,
          },
          setFieldValue,
        } = formProps;

        const form = (
          <Form
            className={styles.form}
            colon={false}
          >
            <FieldArray
              name="goods_info"
              render={(arrayHelpers) => {
                const {
                  remove,
                  push,
                } = arrayHelpers;

                return (
                  <FieldItem
                    name="goods_info"
                    label="待同步商品链接"
                  >
                    {
                      goodsInfo.map((_, index) => {
                        return (
                          <div
                            key={index}
                            className={styles.item}
                          >
                            <Field name={`goods_info.${index}.goods_url`}>
                              {({ field: { name } }) => {
                                const {
                                  field,
                                } = useSmartField(name);
                                return (
                                  <Input
                                    placeholder="黏贴淘宝商品链接"
                                    {...field}
                                  />
                                );
                              }}
                            </Field>
                            <Field name={`goods_info.${index}.cat_ids`}>
                              {({ field: { name } }) => {
                                const {
                                  field,
                                } = useSmartField(name);
                                return (
                                  <Cascader
                                    className={styles.select}
                                    options={goodsClassifyList}
                                    loadData={loadData}
                                    {...field}
                                  />
                                );
                              }}
                            </Field>
                            <CloseCircleFilled
                              className={cx([
                                styles.icon,
                                goodsInfo.length <= 1 && styles.disabled,
                              ])}
                              onClick={goodsInfo.length <= 1 ? undefined : () => remove(index)}
                            />
                          </div>
                        );
                      })
                    }
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<PlusOutlined />}
                      size="small"
                      onClick={() => push({
                        goods_url: '',
                        cat_ids: [],
                      })}
                      disabled={goodsInfo.length >= 5}
                    />
                  </FieldItem>
                );
              }}
            />
            <FieldItem
              name="cost_template_id"
              label="运费模板"
            >
              {({ field }) => {
                return (
                  <Select
                    placeholder="请选择"
                    className={styles.selectLong}
                    {...field}
                  >
                    {
                      shippingTemplatesList.map((item) => {
                        const {
                          template_id: id,
                          template_name: name,
                        } = item;

                        return (
                          <Option
                            value={id}
                            key={id}
                          >
                            {name}
                          </Option>
                        );
                      })
                    }
                  </Select>
                );
              }}
            </FieldItem>
            <FieldItem
              name="goods_type"
              label="商品类型"
            >
              {({ field }) => {
                const {
                  onChange,
                  ...otherField
                } = field;

                function handleChange(e) {
                  const {
                    target: {
                      value,
                    },
                  } = e;

                  if (value === '1' || value === '4') {
                    setFieldValue('country_id', 0);
                  }
                  if (value === '1' || value === '2') {
                    setFieldValue('shipment_limit_second', 48 * 3600);
                  } else {
                    setFieldValue('shipment_limit_second', 120 * 3600);
                  }

                  onChange(value);
                }

                return (
                  <RadioGroup
                    {...otherField}
                    onChange={handleChange}
                  >
                    <Radio value="1">普通商品</Radio>
                    <Radio value="2">进口商品</Radio>
                    <Radio value="4">直邮商品</Radio>
                    <Radio value="3">直供商品</Radio>
                  </RadioGroup>
                );
              }}
            </FieldItem>
            {
              (goodsType === '2' || goodsType === '3') ? (
                <FieldItem
                  name="country_id"
                  label="国家"
                >
                  {({ field }) => {
                    const {
                      value,
                      ...otherField
                    } = field;
                    return (
                      <Select
                        showSearch
                        allowClear
                        placeholder="请选择"
                        className={styles.select}
                        value={value || undefined}
                        {...otherField}
                        filterOption={
                          (input, option) => (
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          )
                        }
                      >
                        {
                          countryList.map((item) => {
                            const {
                              country_id: id,
                              country_name: name,
                            } = item;

                            return (
                              <Option
                                value={id}
                                key={id}
                              >
                                {name}
                              </Option>
                            );
                          })
                        }
                      </Select>
                    );
                  }}
                </FieldItem>
              ) : null
            }
            <FieldItem
              name="second_hand"
              label="是否二手"
            >
              {({ field }) => {
                return (
                  <RadioGroup {...field}>
                    <Radio value={false}>非二手</Radio>
                    <Radio value={!false}>二手</Radio>
                  </RadioGroup>
                );
              }}
            </FieldItem>
            <FieldItem
              name="is_pre_sale"
              label="是否预售"
            >
              {({ field }) => {
                const {
                  onChange,
                  ...otherField
                } = field;

                function handleChange(e) {
                  const {
                    target: {
                      value,
                    },
                  } = e;

                  if (!value) {
                    setFieldValue('pre_sale_time', 0);
                  } else {
                    setFieldValue('pre_sale_time', defaultPreSaleTime);
                  }

                  onChange(value);
                }

                return (
                  <RadioGroup
                    {...otherField}
                    onChange={handleChange}
                  >
                    <Radio value={false}>非预售</Radio>
                    <Radio value={!false}>预售</Radio>
                  </RadioGroup>
                );
              }}
            </FieldItem>
            {
              isPreSale ? (
                <FieldItem
                  name="pre_sale_time"
                  label="预售时间"
                >
                  {({ field }) => {
                    const {
                      value,
                      onChange,
                      ...otherField
                    } = field;
                    return (
                      <DatePicker
                        className={styles.datePicker}
                        value={moment(parseInt(value, 10) * 1000)}
                        onChange={(value) => onChange(value.endOf('days').format('X'))}
                        {...otherField}
                        allowClear={false}
                        disabledDate={(current) => {
                          return current < moment().subtract(1, 'days').endOf('days');
                        }}
                      />
                    );
                  }}
                </FieldItem>
              ) : null
            }
            <FieldItem
              name="shipment_limit_second"
              label="发货时间承诺"
            >
              {({ field }) => {
                const {
                  value,
                  onChange,
                  ...otherField
                } = field;
                return (
                  <RadioGroup
                    value={parseInt(value / 3600, 10)}
                    onChange={({ target: { value } }) => onChange(value * 3600)}
                    {...otherField}
                  >
                    {
                      goodsType === '1' || goodsType === '2' ? (
                        <>
                          <Radio value={48}>48小时发货</Radio>
                          <Radio value={24}>24小时发货</Radio>
                        </>
                      ) : (
                        <Radio value={120}>120小时</Radio>
                      )
                    }
                  </RadioGroup>
                );
              }}
            </FieldItem>
            <FieldItem
              name="is_refundable"
              label="是否七天无理由退货"
            >
              {({ field }) => {
                return (
                  <Radio.Group {...field}>
                    <Radio value={!false}>支持</Radio>
                    <Radio value={false}>不支持</Radio>
                  </Radio.Group>
                );
              }}
            </FieldItem>
            <FieldItem
              name="is_folt"
              label="是否支持假一赔十"
            >
              {({ field }) => {
                return (
                  <RadioGroup {...field}>
                    <Radio value={!false}>支持</Radio>
                    <Radio value={false}>不支持</Radio>
                  </RadioGroup>
                );
              }}
            </FieldItem>
            <FormItem
              label="价格调整"
            >
              <div className={styles.yellowWrapper}>
                <FieldItem
                  name="goods_multi_price_increase"
                  className={styles.inputCom}
                >
                  {({ field }) => {
                    const {
                      value,
                      onChange,
                      ...otherField
                    } = field;

                    function handleSignGroupChange(groupSign) {
                      setGroupSign(parseInt(groupSign, 10));
                      onChange(Math.abs(value) * groupSign);
                    }

                    return (
                      <>
                        <span className={styles.mr10}>团购价比原价</span>
                        <Select
                          className={styles.groupSelect}
                          value={`${groupSign}`}
                          onChange={handleSignGroupChange}
                        >
                          <Option value="1">增加</Option>
                          <Option value="-1">减少</Option>
                        </Select>
                        <InputNumber
                          className={styles.inputNumber}
                          min={0}
                          max={999999}
                          precision={2}
                          placeholder="请输入金额"
                          value={Math.abs(value / 100)}
                          onChange={(value) => onChange(parseInt(value * 100 * groupSign, 10))}
                          {...otherField}
                        />
                        <span>元</span>
                      </>
                    );
                  }}
                </FieldItem>
                <div className={styles.light}>
                  (价格调整幅度根据拼多多规则，
                  <span className={styles.blue}>
                    不能超过同步商品价格的8%
                  </span>
                  ，如果调整幅度超过8%，会自动调整为8%)
                </div>
              </div>
              <div className={styles.yellowWrapper}>
                <FieldItem
                  name="goods_price_increase"
                  className={styles.inputCom}
                >
                  {({ field }) => {
                    const {
                      value,
                      onChange,
                      ...otherField
                    } = field;
                    return (
                      <>
                        <span>单买价比团购价增加</span>
                        <InputNumber
                          min={1}
                          max={999999}
                          precision={2}
                          className={styles.inputNumber}
                          placeholder="请输入金额"
                          value={value / 100}
                          onChange={(value) => onChange(parseInt(value * 100, 10))}
                          {...otherField}
                        />
                        <span>元</span>
                      </>
                    );
                  }}
                </FieldItem>
                <div className={styles.light}>
                  (单买价格
                  <span className={styles.blue}>
                    只能进行增加
                  </span>
                  ，根据拼多多规则必须
                  <span className={styles.blue}>
                    比团购价高至少1元
                  </span>
                  )
                </div>
              </div>
            </FormItem>
            <FieldItem
              name="sensitive_words"
              label={(
                <div>
                  商品标题文字
                  <br />
                  <span className={styles.light}>(选填)</span>
                </div>
              )}
            >
              {({ field }) => {
                const {
                  value,
                  onChange,
                  ...otherField
                } = field;

                function handleChange(e) {
                  const {
                    target: {
                      value,
                    },
                  } = e;
                  onChange(value.split(','));
                }

                return (
                  <div className={styles.yellowWrapper}>
                    <div>商品标题中需要删除的文字</div>
                    <TextArea
                      placeholder="输入关键词，多个关键词用英文逗号隔开（如：淘宝）"
                      value={value.join(',')}
                      onChange={handleChange}
                      {...otherField}
                    />
                  </div>
                );
              }}
            </FieldItem>
          </Form>
        );

        return children(form, formProps);
      }}
    </Formik>
  );
}

export default ItemSyncForm;
