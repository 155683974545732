import { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  render() {
    const {
      props: {
        fallback,
        children,
      },
      state: {
        hasError,
        error,
      },
    } = this;

    if (hasError) {
      if (typeof fallback === 'function') {
        return fallback(error);
      }

      return fallback;
    }

    return children;
  }
}

export default ErrorBoundary;
