import React, { useState } from 'react';
import { Card } from 'antd';
import Placeholder, { Type } from '../../components/Placeholder';
import OptimizeItem from './components/OptimizeItem';
import styles from './styles.module.css';

const ItemOptimize = () => {
  const limitWords = ['淘宝', '京东', '天猫', '旗舰店'];
  const [isResultShow, setIsResultShow] = useState(false);

  return isResultShow ? (
    <Placeholder
      type={Type.SUCCESS}
      text="YEAH！优化成功啦！"
      buttonProps={{
        onClick: () => setIsResultShow(false),
      }}
    />
  ) : (
    <Card
      title="商品同步"
      className={styles.card}
    >
      <div className={styles.body}>
        {
          limitWords.map((item, index) => (
            <div
              key={index}
              className={styles.item}
            >
              <OptimizeItem
                title="自动去违禁词"
                item={item}
                desc={`'去除关键词“${item}”'`}
                createTime="2020/03/14 23:02:44"
                modifiedTime="2020/03/14 23:02:44"
                onSuccess={() => setIsResultShow(true)}
              />
            </div>
          ))
        }
      </div>
      <div className={styles.more}>
        <span>更多优化方式，敬请期待</span>
      </div>
    </Card>
  );
};

export default ItemOptimize;
