import React, { useContext } from 'react';
import {
  Card,
  Input,
  Button,
  Popconfirm,
  Badge,
  Table,
  Empty,
  Spin,
  message,
} from 'antd';
import { css } from 'emotion';
import Mark from '../../../../components/Mark';
import useSearch, { TaskStatus } from './useSearch';
import redcapApi from '../../../../apis/redcapApi';
import { ItemSyncContext, TabKeys } from '../../useItemSync';
import { ReactComponent as EmptyIcon } from './assets/empty.svg';

const {
  Search,
} = Input;

const {
  Column,
} = Table;

const styles = {
  card: css`
    height: 100%;
    overflow: auto;
  `,
  search: css`
    width: 270px;
  `,
  buttonWrapper: css`
    margin: 20px 0;
    > button:not(:last-child) {
      margin-right: 24px;
    }
  `,
  table: css`
    width: 100%;
  `,
  itemInfo: css`
    tbody & {
      display: flex;
      align-items: center;
      padding: 10px !important;
      overflow: hidden;
      > img {
        flex: 0 0 50px;
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }
      > div {
        flex: 1 0 0;
        overflow: hidden;
        > a {
          display: inline-block;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        > p {
          width: 100%;
          margin: 0;
          color: rgba(0, 0, 0, .45);
        }
      }
    }
  `,
};

function SyncHistory() {
  const {
    inputValue,
    setInputValue,
    loading,
    keywords,
    search,
    queryStatus,
    data,
    pages,
  } = useSearch();

  const {
    total,
    successTotal,
    failedTotal,
  } = pages;

  let pageTotal = total;
  if (queryStatus === TaskStatus.SUCCESS) {
    pageTotal = successTotal;
  }
  if (queryStatus === TaskStatus.FAIL) {
    pageTotal = failedTotal;
  }

  const keywordsReg = keywords ? new RegExp(keywords, 'i') : undefined;

  const {
    setActiveKey,
    setInitialValues,
  } = useContext(ItemSyncContext);

  return (
    <Card className={styles.card}>
      <h2>同步记录</h2>
      <div>
        <Search
          className={styles.search}
          value={inputValue}
          onChange={({ target: { value } }) => setInputValue(value)}
          placeholder="商品名称"
          disabled={loading}
          onSearch={(keywords) => search({ keywords })}
        />
        {
          keywords && (
            <Button
              type="link"
              onClick={() => search({ keywords: '' })}
            >
              返回
            </Button>
          )
        }
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          size="small"
          shape="round"
          disabled={loading}
          type={queryStatus === TaskStatus.ALL ? 'primary' : ''}
          onClick={() => search({ taskStatus: TaskStatus.ALL })}
        >
          {`全部(${total})`}
        </Button>
        <Button
          size="small"
          shape="round"
          disabled={loading}
          type={queryStatus === TaskStatus.SUCCESS ? 'primary' : ''}
          onClick={() => search({ taskStatus: TaskStatus.SUCCESS })}
        >
          {`同步成功商品(${successTotal})`}
        </Button>
        <Button
          size="small"
          shape="round"
          disabled={loading}
          type={queryStatus === TaskStatus.FAIL ? 'primary' : ''}
          onClick={() => search({ taskStatus: TaskStatus.FAIL })}
        >
          {`同步失败商品(${failedTotal})`}
        </Button>
      </div>
      {
        data.length ? (
          <Table
            className={styles.table}
            dataSource={data}
            rowKey="task_code"
            loading={loading}
            pagination={{
              ...pages,
              total: pageTotal,
              showTotal: (total) => `共 ${total} 项`,
            }}
            onChange={search}
          >
            <Column
              title="商品"
              className={styles.itemInfo}
              ellipsis
              width={400}
              render={(record) => {
                const {
                  goods_thumb: goodsThumb,
                  goods_title: goodsTitle,
                  goods_id: goodsId,
                } = record;

                return (
                  <>
                    <img
                      src={goodsThumb}
                      alt="商品图片"
                    />
                    <div>
                      <Mark
                        component="a"
                        reg={keywordsReg}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {goodsTitle}
                      </Mark>
                      <p>
                        ID：
                        {
                          goodsId === '0' ? '-' : (
                            <Mark reg={keywordsReg}>
                              {`${goodsId}`}
                            </Mark>
                          )
                        }
                      </p>
                    </div>
                  </>
                );
              }}
            />
            <Column
              title="状态"
              dataIndex="task_status"
              width={100}
              render={(value) => {
                return (
                  <Badge
                    color={value === TaskStatus.SUCCESS ? 'green' : 'red'}
                    text={value === TaskStatus.SUCCESS ? '成功' : '失败'}
                  />
                );
              }}
            />
            <Column
              title="提交时间"
              dataIndex="create_time"
              width={200}
            />
            <Column
              title="操作"
              width={300}
              align="center"
              render={(record) => {
                const {
                  task_status: taskStatus,
                  task_code: taskId,
                } = record;

                function retry() {
                  return redcapApi
                    .fetchRetrySyncInfo(taskId)
                    .then((res) => {
                      const {
                        params: {
                          goods_url: goodsUrl,
                          cat_ids: catIds,
                          goods_type: goodsType,
                          sensitive_words: sensitiveWords,
                          country_id: countryId,
                          ...otherParams
                        },
                      } = res;

                      setInitialValues({
                        ...otherParams,
                        goods_info: [{
                          goods_url: goodsUrl,
                          cat_ids: catIds,
                        }],
                        goods_type: `${goodsType}`,
                        sensitive_words: sensitiveWords.split(','),
                        country_id: countryId === '0' ? undefined : countryId,
                      });
                      setActiveKey(TabKeys.ITEM_SYNC);
                    })
                    .catch(() => {
                      message.error('重新尝试失败，请稍后再试');
                    });
                }

                function deleteTask() {
                  return redcapApi
                    .deleteSyncInfo(taskId)
                    .then(search)
                    .catch(() => {
                      message.error('删除失败，请稍后再试');
                    });
                }

                return (
                  <>
                    {
                      taskStatus === TaskStatus.FAIL && (
                        <Button
                          type="link"
                          onClick={retry}
                        >
                          重新尝试
                        </Button>
                      )
                    }
                    <Popconfirm
                      title="确定删除当前记录？"
                      onConfirm={deleteTask}
                    >
                      <Button
                        type="link"
                      >
                        删除记录
                      </Button>
                    </Popconfirm>
                  </>
                );
              }}
            />
          </Table>
        ) : (
          <Spin spinning={loading}>
            <Empty
              image={<EmptyIcon />}
              description="没有找到对应内容，请重新查询"
            />
          </Spin>
        )
      }
    </Card>
  );
}

export default SyncHistory;
