import Restful from '../utils/Restful';
import { zhisuiClient } from './clients';

class RedcapApi extends Restful {
  fetchShop() {
    return this
      .one('GetMallInfo')
      .get();
  }

  fetch(params) {
    return this
      .one('getGoodsList')
      .get({ params });
  }

  updateGoodQuantity(body) {
    return this
      .one('updateGoodQuantity')
      .post(body);
  }

  updatGoodStatus(body) {
    return this
      .one('UpdatGoodStatus')
      .post(body);
  }

  updatGoodsStatus(body) {
    return this
      .one('UpdatGoodsStatus')
      .post(body);
  }

  updateGoodName(body) {
    return this
      .one('UpdateGoodName')
      .post(body);
  }

  fetchGoodsClassify(params) {
    return this
      .one('getPddGoodsCats')
      .get({ params });
  }

  fetchShippingTemplates(params) {
    return this
      .one('getPddGoodsLogisticsTemplate')
      .get({ params });
  }

  submitItemSync(body) {
    return this
      .one('syncGoodsInfo2Pdd')
      .post(body);
  }

  fetchCountry() {
    return this
      .one('getPddGoodsCountry')
      .get();
  }

  fetchSyncHistory(params) {
    return this
      .custom('tasks')
      .get({
        params,
      });
  }

  fetchRetrySyncInfo(taskId) {
    return this
      .custom('task/params')
      .get({
        params: {
          task_code: taskId,
        },
      });
  }

  deleteSyncInfo(taskId) {
    return this
      .custom('tasks/multiple')
      .post({
        task_codes: [`${taskId}`],
      });
  }
}

export default new RedcapApi({
  client: zhisuiClient,
  entry: '/v1',
});
