function withAuth(auth) {
  return (Client) => {
    class AuthClient extends Client {
      // eslint-disable-next-line no-useless-constructor
      constructor(...args) {
        super(...args);
      }

      authorize(...args) {
        return auth.authorize(this, ...args);
      }
    }

    return AuthClient;
  };
}

export default withAuth;
