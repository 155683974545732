import { useState, useMemo, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { parse } from 'query-string';
import { decode } from 'jsonwebtoken';
import { authorize } from '../../../apis/clients/index';

export const STORAGE_TOKEN_NAME = 'AUTH_TOKEN';

const useAuth = () => {
  const [authing, setAuthing] = useState(true);
  const [authed, setAuthed] = useState(false);
  const [authInfo, setAuthInfo] = useState();

  const { search, pathname } = useLocation();
  const history = useHistory();
  const { auth_token: queryAuthToken } = useMemo(() => parse(search), [search]);
  const [storageAuthToken, setStorageAuthToken] = useLocalStorage(STORAGE_TOKEN_NAME);

  useEffect(() => {
    let authToken = '';

    if (queryAuthToken && typeof queryAuthToken === 'string') {
      setStorageAuthToken(queryAuthToken);
      authToken = queryAuthToken;
    } else if (storageAuthToken) {
      authToken = storageAuthToken;
    }

    if (!authToken) {
      setAuthed(false);
    } else {
      const authInfo = decode(authToken);

      if (!authInfo || Date.now() > authInfo.exp * 1e3) {
        setAuthed(false);
      } else {
        setAuthed(true);
        setAuthInfo(authInfo);

        if (pathname !== '/401') {
          history.replace(pathname);
        }

        authorize(authToken);
      }
    }

    setAuthing(false);
  }, [queryAuthToken, storageAuthToken, history, pathname, setStorageAuthToken]);

  return {
    authing,
    authed,
    authInfo,
  };
};

export default useAuth;
