import { useCallback } from 'react';
import { useField } from 'formik';

function useSmartField(name, validate) {
  const [
    field,
    meta,
    helpers,
  ] = useField({
    name,
    validate,
  });

  const {
    value,
  } = field;

  const onChange = useCallback((eventOrValue) => {
    const {
      target: {
        checked = eventOrValue,
        value = checked,
      } = {},
    } = eventOrValue || {};

    helpers.setValue(value);
  }, [helpers]);

  const onBlur = useCallback(() => {
    helpers.setTouched(true);
  }, [helpers]);

  return {
    field: {
      name,
      value,
      onChange,
      onBlur,
    },
    meta,
    helpers,
  };
}

export default useSmartField;
