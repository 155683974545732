import React, {
  useState,
  useEffect,
} from 'react';
import {
  Link,
  useLocation,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { useSessionStorage } from 'react-use';
import {
  Layout,
  Menu,
  Spin,
  Empty,
  message,
} from 'antd';
import ItemManagement from '../ItemManagement';
import ItemSync from '../ItemSync';
import ItemOptimize from '../ItemOptimize';
import MultipleManagement from '../MultipleManagment';
import useAuth from './hooks/useAuth';
import { ReactComponent as LogoIcon } from './assets/logo.svg';
import { ReactComponent as LogoTextIcon } from './assets/logoText.svg';
import { ReactComponent as ItemManagementIcon } from './assets/itemManagement.svg';
import { ReactComponent as ItemSyncIcon } from './assets/itemSync.svg';
import { ReactComponent as ItemOptimizeIcon } from './assets/itemOptimize.svg';
import { ReactComponent as MultipleManagementIcon } from './assets/multipleManagement.svg';
import redcap from '../../apis/redcapApi';
import styles from './styles.module.css';

const {
  Item: MenuItem,
} = Menu;

const {
  Header,
  Sider,
  Content,
} = Layout;

const routes = [
  {
    label: '商品管理',
    path: '/itemManagement',
    icon: <ItemManagementIcon />,
    component: ItemManagement,
  },
  {
    label: '商品同步',
    path: '/itemSync',
    icon: <ItemSyncIcon />,
    component: ItemSync,
  },
  {
    label: '商品优化',
    path: '/itemOptimize',
    icon: <ItemOptimizeIcon />,
    component: ItemOptimize,
  },
  {
    label: '多店管理',
    path: '/multipleManagement',
    icon: <MultipleManagementIcon />,
    component: MultipleManagement,
  },
];

const Main = () => {
  const {
    authing,
    authed,
  } = useAuth();

  const { pathname } = useLocation();

  const [selectedKeys, setSelectedKeys] = useState([]);
  const [shopInfo, setShopInfo] = useState({});
  const [, setStorageShopName] = useSessionStorage('SHOP_NAME');

  useEffect(() => {
    if (!authed) {
      return;
    }

    redcap
      .fetchShop()
      .then((res) => {
        setShopInfo(res.data);
        setStorageShopName(res.data.mall_name);
      })
      .catch((err) => {
        message.error(err.message || '获取店铺信息失败');
      });
  }, [authed, setStorageShopName]);

  useEffect(() => {
    const activeRoute = routes.find(({ path }) => path === pathname);
    if (activeRoute) {
      setSelectedKeys([activeRoute.path]);
    } else {
      setSelectedKeys([]);
    }
  }, [pathname]);

  if (authing) {
    return (
      <Empty
        className={styles.empty}
        image={null}
        description={null}
      >
        <Spin
          delay={300}
          spinning
          tip="授权中..."
          size="large"
        />
      </Empty>
    );
  }

  if (!authed) {
    return (
      <Redirect to="/401" />
    );
  }

  return (
    <Layout className={styles['main-layout']}>
      <Sider theme="light">
        <div className={styles['logo-wrapper']}>
          <LogoIcon className={styles.logo} />
          <LogoTextIcon />
        </div>
        <Menu
          mode="inline"
          multiple={false}
          selectedKeys={selectedKeys}
        >
          {
            routes.map((route) => {
              const {
                label,
                path,
                icon,
              } = route;

              return (
                <MenuItem key={path}>
                  <Link to={path}>
                    {icon}
                    {label}
                  </Link>
                </MenuItem>
              );
            })
          }
        </Menu>
      </Sider>
      <Layout>
        <Header>
          <div className={styles.shop}>
            {
              shopInfo.logo && (
                <img
                  src={shopInfo.logo}
                  alt="商家头像"
                />
              )
            }
            <span>{shopInfo.mall_name}</span>
          </div>
        </Header>
        <Content>
          <Switch>
            {
              routes.map((route) => {
                return (
                  <Route
                    key={route.path}
                    {...route}
                  />
                );
              })
            }
            <Route path="*" component={() => <Redirect to="/itemManagement" />} />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Main;
