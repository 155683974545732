import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useSessionStorage } from 'react-use';
import {
  Card,
  Button,
  Table,
  Popconfirm,
  message,
} from 'antd';
// import { PlusOutlined } from '@ant-design/icons';
import CardHeader from '../../components/CardHeader';
import { ReactComponent as FloatIcon } from './assets/floatIcon.svg';
import styles from './styles.module.css';
import wait from '../../utils/wait';

const MultipleManagement = () => {
  const [adding] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [shopName] = useSessionStorage('SHOP_NAME');
  // const [inputValue, setInputValue] = useState('');
  // const [relating, setRelating] = useState(false);

  useEffect(() => {
    setDataSource([
      {
        name: shopName,
        isMain: true,
      },
    ]);
  }, [shopName]);

  const handleCancel = useCallback(() => {
    wait(500)
      .then(() => {
        message.success('取消关联成功');
      });
  }, []);

  const handleTransfer = useCallback(() => {
    wait(500)
      .then(() => {
        message.success('转让成功');
      });
  }, []);

  // const handleRelate = useCallback(() => {
  //   setRelating(true);

  //   wait(1000)
  //     .then(() => {
  //       setRelating(false);
  //       setAdding(false);
  //       setDataSource((dataSource) => dataSource.slice(1));
  //       setInputValue('');
  //       message.success('关联成功');
  //     });
  // }, []);

  const renderContent = useCallback((value, _, index) => {
    const obj = {
      children: value,
      props: {},
    };

    if (adding && index === 0) {
      obj.props.colSpan = 0;
    }

    return obj;
  }, [adding]);

  const columns = useMemo(() => [
    // {
    //   title: 'SID',
    //   dataIndex: 'sid',
    //   key: 'sid',
    //   render: (sid, record, index) => {
    //     const {
    //       isMain,
    //     } = record;

    //     if (index === 0 && adding) {
    //       return {
    //         children: (
    //           <div>
    //             <Input
    //               className={styles.input}
    //               placeholder="请输入店铺SID"
    //               value={inputValue}
    //               onChange={(e) => setInputValue(e.target.value)}
    //             />
    //             <Button
    //               type="primary"
    //               disabled={!inputValue}
    //               loading={relating}
    //               onClick={handleRelate}
    //             >
    //               关联
    //             </Button>
    //           </div>
    //         ),
    //         props: {
    //           colSpan: 4,
    //         },
    //       };
    //     }

    //     return isMain ? (
    //       <div>
    //         <span className={styles.vab}>{sid}</span>
    //         <Tag
    //           className={styles.tag}
    //           color="#FB9158"
    //         >
    //           主店铺
    //         </Tag>
    //       </div>
    //     ) : sid;
    //   },
    // },
    {
      title: '店铺名称',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: renderContent,
    },
    {
      title: '店铺编号',
      dataIndex: 'sid',
      key: 'sid2',
      align: 'center',
      render: renderContent,
    },
    {
      title: '操作',
      key: 'operation',
      align: 'center',
      render: (record, _, index) => {
        const {
          sid,
          isMain,
        } = record;

        return renderContent(
          isMain ? null : (
            <div className={styles['button-wrapper']}>
              <Popconfirm
                placement="bottom"
                cancelText="点错了"
                okText="确定"
                title="确定取消关联？"
                icon={null}
                disabled={adding}
                onConfirm={() => handleCancel(sid)}
              >
                <Button
                  type="primary"
                  ghost
                  disabled={adding}
                >
                  取消关联
                </Button>
              </Popconfirm>
              <Popconfirm
                placement="bottom"
                cancelText="点错了"
                okText="确定"
                title="确定转让主店？"
                icon={null}
                disabled={adding}
                onConfirm={() => handleTransfer(sid)}
              >
                <Button
                  type="primary"
                  ghost
                  disabled={adding}
                >
                  转让主店
                </Button>
              </Popconfirm>
            </div>
          ),
          _,
          index,
        );
      },
    },
  ], [
    handleCancel,
    handleTransfer,
    adding,
    renderContent,
  ]);

  // const handleAddClick = useCallback(() => {
  //   setDataSource((dataSource) => [{ sid: '@@sid@@key' }, ...dataSource]);
  //   setAdding(true);
  // }, []);

  return (
    <Card
      className={styles.card}
      title={(
        <CardHeader
          title="关联店铺列表"
          subTitle="主店铺主要用于管理商家多个店铺的绑定关系，用于在商品管理页面，同步商品处理，主店铺与子店铺并未有其他权限不同"
        />
      )}
    >
      {/* <div className={styles.controller}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          disabled={adding}
          onClick={handleAddClick}
        >
          添加关联店铺
        </Button>
      </div> */}
      <Table
        dataSource={dataSource}
        columns={columns}
        rowKey={(_, index) => index}
        pagination={false}
      />
      <a
        href="https://visionet.weisk.com/webchat/index.html?appKey=557a47963a360df6ae9e4851f3eca8e5"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FloatIcon className={styles['float-button']} />
      </a>
    </Card>
  );
};

export default MultipleManagement;
