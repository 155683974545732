import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { ConfigProvider, Alert } from 'antd';
import locale from 'antd/es/locale/zh_CN';
import Main from './views/Main';
import Forbidden from './views/Main/Forbidden';
import ErrorBoundary from './components/ErrorBoundary';
import history from './utils/history';

const App = () => {
  return (
    <Router history={history}>
      <ConfigProvider locale={locale}>
        <ErrorBoundary
          fallback={() => {
            return (
              <Alert
                showIcon
                type="error"
                message="应用加载失败"
                description="应用加载失败，请稍后尝试刷新浏览器"
              />
            );
          }}
        >
          <Switch>
            <Route
              exact
              path="/401"
              component={Forbidden}
            />
            <Route
              path="/"
              component={Main}
            />
          </Switch>
        </ErrorBoundary>
      </ConfigProvider>
    </Router>
  );
};

export default App;
