/* eslint-disable */

import React from 'react'
import { css } from 'emotion'

import matchAll from '../../utils/matchAll'

export function defaultMarkRender (str) {
  return (
    <mark className={css`
      position: relative;
      background: transparent;
      color: currentColor;
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 8px;
        background: #FFBF00;
        border-radius: 4px;
      }
      > span {
        position: relative;
        z-index: 1;
      }
    `}>
      <span>{str}</span>
    </mark>
  )
}

function markNode (reg, ignores, render, node, markProps, index) {
  if (node === null || node === undefined) {
    return null
  }

  if (Array.isArray(node)) {
    return node.map((x, index) => markNode(reg, ignores, render, x, markProps, index))
  }

  const {
    type: Component,
    key,
    props: {
      children,
      ...otherProps
    } = {}
  } = node

  if (Component) {
    if (ignores && ((Array.isArray(ignores) && ignores.indexOf(Component)) || Component === ignores)) {
      return node
    }

    return (
      <Component
        {...otherProps}
        key={key || index}
      >
        {markNode(reg, ignores, render, children, markProps)}
      </Component>
    )
  }

  if (typeof node === 'string') {
    const matches = matchAll(reg, node)

    if (!matches.length) {
      return node
    }

    const matchesLastIndex = matches.length - 1

    return matches.map((x, i) => {
      const {
        index,
        0: str
      } = x
      const {
        [i - 1]: {
          index: prevIndex,
          0: prevStr
        } = {}
      } = matches

      return (
        <span key={i} >
          {
            node.slice(i > 0 ? prevIndex + prevStr.length : 0, index)
          }
          {
            render(str, markProps)
          }
          {
            i === matchesLastIndex && node.slice(index + str.length, node.length)
          }
        </span>
      )
    })
  }

  return null
}

function Mark (props) {
  const {
    component: Component = 'span',
    markRender = defaultMarkRender,
    reg,
    ignores,
    markProps = {},
    children,
    ...otherProps
  } = props

  return (
    <Component {...otherProps} >
      {
        reg
          ? markNode(reg, ignores, markRender, children, markProps)
          : children
      }
    </Component>
  )
}

export default Mark
