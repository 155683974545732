import React from 'react';
import { Card, Tabs } from 'antd';
import { css } from 'emotion';
import ItemSyncContainer from './contianers/itemSync';
import SyncHistory from './contianers/syncHistory';
import useItemSync, { Provider, TabKeys } from './useItemSync';

const styles = {
  container: css`
    height: 100%;
    display: flex;
    flex-direction: column;
  `,
  card: css`
    margin-bottom: 10px;
    .ant-card-body {
      padding: 5px 24px;
    }
  `,
  tabs: css`
    .ant-tabs-nav {
      margin-bottom: 0;
    }
    .ant-tabs-nav::before {
      display: none;
    }
  `,
  content: css`
    flex: 1 0 0;
    overflow: hidden;
  `,
};

const {
  TabPane,
} = Tabs;

function ItemSync() {
  const {
    activeKey,
    setActiveKey,
    contextValue,
  } = useItemSync();

  return (
    <Provider value={contextValue}>
      <div className={styles.container}>
        <Card className={styles.card}>
          <Tabs
            className={styles.tabs}
            activeKey={activeKey}
            onChange={setActiveKey}
          >
            <TabPane
              tab="商品同步"
              key={TabKeys.ITEM_SYNC}
            />
            <TabPane
              tab="同步记录"
              key={TabKeys.SYNC_HISTORY}
            />
          </Tabs>
        </Card>
        <div className={styles.content}>
          {
            activeKey === TabKeys.ITEM_SYNC ? (
              <ItemSyncContainer />
            ) : (
              <SyncHistory />
            )
          }
        </div>
      </div>
    </Provider>
  );
}

export default ItemSync;
