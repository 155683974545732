import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react';
import {
  Card,
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Table,
  Modal,
  Alert,
  message,
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
} from '@ant-design/icons';
import CardHeader from '../../components/CardHeader';
import cn from '../../utils/classNames';
import redcapApi from '../../apis/redcapApi';
import styles from './styles.module.css';

const {
  Item: FormItem,
} = Form;

const {
  Option,
} = Select;

const {
  TextArea,
} = Input;

const ItemStatus = {
  UP: '1',
  DOWN: '2',
};

const Inventory = (props) => {
  const {
    goods_quantity: goodsQuantity,
    goods_id: goodsId,
    sku_list: skuList,
    onSuccess,
  } = props;

  const [modalVisible, setModalVisible] = useState(false);
  const [editingSku, setEditingSku] = useState({});

  const handleSave = useCallback(() => {
    if (!editingSku.sku_id) {
      setModalVisible(false);
      return;
    }

    redcapApi
      .updateGoodQuantity({
        goods_id: goodsId,
        quantity: editingSku.sku_quantity,
        sku_id: editingSku.sku_id,
      })
      .then(() => {
        setEditingSku({});
        onSuccess({
          goodsId,
          editingSku,
        });
        setModalVisible(false);
      })
      .catch(() => {
        message.error('修改库存失败');
      });
  }, [goodsId, editingSku, onSuccess]);

  return (
    <div>
      <span>{goodsQuantity}</span>
      <Button
        type="link"
        icon={<EditOutlined />}
        onClick={() => setModalVisible(true)}
      />
      <Modal
        title="修改库存"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handleSave}
      >
        <ItemInfo {...props} />
        <Table
          className={styles.mt20}
          dataSource={skuList}
          columns={[
            {
              title: 'SKU',
              dataIndex: 'spec',
              key: 'spec',
            },
            {
              title: '库存',
              dataIndex: 'sku_quantity',
              key: 'sku_quantity',
              render: (skuQuantity, record) => {
                const {
                  sku_id: skuId,
                } = record;

                return editingSku.sku_id === skuId ? (
                  <div>
                    <InputNumber
                      value={editingSku.sku_quantity}
                      onChange={(value) => setEditingSku((sku) => ({
                        ...sku,
                        sku_quantity: value,
                      }))}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          setEditingSku((sku) => ({
                            ...sku,
                            sku_quantity: 0,
                          }));
                        }
                      }}
                      precision={0}
                      min={0}
                    />
                  </div>
                ) : (
                  <div>
                    <span>{skuQuantity}</span>
                    <Button
                      type="link"
                      icon={<EditOutlined />}
                      onClick={() => setEditingSku({ ...record })}
                    />
                  </div>
                );
              },
            },
          ]}
          rowKey="sku_id"
        />
      </Modal>
    </div>
  );
};

const ItemInfo = (props) => {
  const {
    thumb_url: thumbUrl,
    goods_id: goodsId,
    goods_name: goodsName,
  } = props;

  return (
    <div className={styles['item-info']}>
      <img
        height="54"
        src={thumbUrl}
        alt="商品图片"
      />
      <div>
        <div className={styles.title}>{goodsName}</div>
        <div className={styles.desc}>
          <span>{`商品ID：${goodsId}`}</span>
          <span>{`商品编号：${goodsId}`}</span>
        </div>
      </div>
    </div>
  );
};

const ItemManagement = () => {
  const [dataSource, setDataSource] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingItem, setEditingItem] = useState({});
  const [newTitle, setNewTitle] = useState('');
  const [itemName, setItemName] = useState('');
  const [outerId, setOuterId] = useState('');
  const [itemStatus, setItemStatus] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [outGoodsId, setOutGoodsId] = useState([]);
  const [outGoodsIdText, setOutGoodsIdText] = useState();
  const [templateId, setTemplateId] = useState();

  const handleSearch = useCallback((pagination = {}) => {
    setSelectedRowKeys([]);
    const {
      current = 1,
      pageSize = 10,
    } = pagination;

    const params = {
      page: current,
      pageSize,
    };

    if (outerId) {
      params.outer_id = outerId;
    }
    if (itemName) {
      params.goods_name = itemName;
    }
    if (itemStatus) {
      params.is_onsale = itemStatus;
    }
    if (outGoodsId) {
      params.outer_goods_id = outGoodsId.toString();
    }
    if (templateId) {
      params.cost_template_id = templateId;
    }
    redcapApi
      .fetch(params)
      .then((res) => {
        const {
          data: {
            total_count: total,
            goods_list: dataSource,
          },
        } = res;
        setTotal(total);
        setCurrent(current);
        setPageSize(pageSize);
        setDataSource(dataSource);
      })
      .catch((err) => {
        message.error(err.message || '获取商品列表失败');
      });
  }, [outerId, itemStatus, itemName, outGoodsId, templateId]);

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line
  }, []);

  const updateGoodStatus = useCallback(({ goodsId, isOnsale }) => {
    redcapApi
      .updatGoodStatus({
        goods_id: goodsId,
        is_onsale: isOnsale,
      })
      .then(() => {
        setDataSource((dataSource) => {
          const index = dataSource.findIndex(({ goods_id: id }) => id === goodsId);
          dataSource[index] = {
            ...dataSource[index],
            is_onsale: isOnsale === 0 ? 2 : 1,
          };
          return [...dataSource];
        });
        message.success('修改商品状态成功');
      })
      .catch((err) => {
        message.error(err.message || '修改商品状态失败');
      });
  }, []);

  const updateGoodsStatus = useCallback(({ isOnsale }) => {
    redcapApi
      .updatGoodsStatus({
        list: selectedRowKeys.map((goodsId) => ({
          goods_id: goodsId,
          is_onsale: isOnsale,
        })),
      })
      .then(() => {
        setDataSource((dataSource) => {
          selectedRowKeys.forEach((key) => {
            const index = dataSource.findIndex(({ goods_id: id }) => id === key);
            dataSource[index] = {
              ...dataSource[index],
              is_onsale: isOnsale === 0 ? 2 : 1,
            };
          });
          return [...dataSource];
        });
        message.success(`批量${isOnsale === 1 ? '上' : '下'}架成功`);
      })
      .catch(() => {
        message.error(`批量${isOnsale === 1 ? '上' : '下'}架失败`);
      });
  }, [selectedRowKeys]);

  const optimizeTitle = useCallback(() => {
    redcapApi
      .updateGoodName({
        goods_id: editingItem.goods_id,
        goods_name: newTitle,
      })
      .then(() => {
        setDataSource((dataSource) => {
          const index = dataSource.findIndex(({ goods_id: id }) => id === editingItem.goods_id);
          dataSource[index] = {
            ...dataSource[index],
            goods_name: newTitle,
          };
          return [...dataSource];
        });
        message.success('标题优化成功');
        setModalVisible(false);
      })
      .catch((err) => {
        message.error(err.message || '标题优化失败');
      });
  }, [editingItem, newTitle]);

  const handleReset = useCallback(() => {
    setItemName('');
    setItemStatus();
    setOuterId();
    setOutGoodsId([]);
    setTemplateId();
    setOutGoodsIdText();
    handleSearch();
  }, [handleSearch]);

  const handleSelectChange = useCallback((value) => {
    if (value.length > 10) { return; }
    if (value.length === 0) {
      setOutGoodsIdText();
    }
    setOutGoodsId(value);
  }, []);

  const handleSelectSearch = useCallback((value) => {
    setOutGoodsIdText(value);
  }, []);

  const columns = useMemo(() => [
    {
      key: 'itemInfo',
      title: '商品信息',
      width: 400,
      render: (record) => {
        return <ItemInfo {...record} />;
      },
    },
    {
      dataIndex: 'last_category',
      key: 'last_category',
      title: '商品分类',
      align: 'center',
      width: 100,
    },
    {
      dataIndex: 'goods_price',
      key: 'goods_price',
      title: '价格（元）',
      align: 'center',
      width: 100,
    },
    {
      key: 'goodsQuantity',
      title: '库存',
      align: 'center',
      width: 150,
      render: (record) => {
        return (
          <Inventory
            onSuccess={({
              goodsId,
              editingSku,
            }) => {
              setDataSource((dataSource) => {
                const index = dataSource.findIndex(({ goods_id: id }) => id === goodsId);
                const skuIndex = dataSource[index].sku_list
                  .findIndex(({ sku_id: skuId }) => skuId === editingSku.sku_id);
                dataSource[index].sku_list[skuIndex] = editingSku;
                dataSource[index] = {
                  ...dataSource[index],
                  goods_quantity: dataSource[index].sku_list.reduce((res, cur) => {
                    return res + parseInt(cur.sku_quantity, 10);
                  }, 0),
                  sku_list: [...dataSource[index].sku_list],
                };
                return [...dataSource];
              });
            }}
            {...record}
          />
        );
      },
    },
    {
      dataIndex: 'is_onsale',
      key: 'isOnsale',
      title: '商品状态',
      align: 'center',
      width: 100,
      render: (isOnsale) => {
        return (
          <span className={isOnsale === 2 ? styles.failed : styles.success}>
            {isOnsale === 2 ? '已下架' : '已上架'}
          </span>
        );
      },
    },
    {
      title: '操作',
      align: 'center',
      width: 240,
      render: (record) => {
        const {
          goods_id: goodsId,
          is_onsale: isOnsale,
        } = record;

        return (
          <div>
            <Button
              type="link"
              disabled={isOnsale === 1}
              onClick={() => updateGoodStatus({ goodsId, isOnsale: 1 })}
            >
              上架
            </Button>
            <Button
              type="link"
              disabled={isOnsale === 2}
              onClick={() => updateGoodStatus({ goodsId, isOnsale: 0 })}
            >
              下架
            </Button>
            <Button
              type="link"
              onClick={() => {
                setModalVisible(true);
                setEditingItem(record);
                setNewTitle('');
              }}
            >
              标题优化
            </Button>
          </div>
        );
      },
    },
  ], [updateGoodStatus]);

  return (
    <div>
      <Card className={styles.card}>
        <div className={styles['search-wrapper']}>
          <div>
            <FormItem label="商品外部编码">
              <Input
                placeholder="请输入商品外部编码"
                value={outerId}
                onChange={(e) => setOuterId(e.target.value)}
                allowClear
              />
            </FormItem>
            <FormItem label="商家外部商品编码">
              <Select
                mode="tags"
                placeholder="请输入商家外部商品编码"
                className={styles.select}
                onChange={handleSelectChange}
                onSearch={handleSelectSearch}
                value={outGoodsId}
                allowClear
                dropdownRender={outGoodsIdText && outGoodsId ? null : () => (
                  <div className={styles['select-render']}>
                    可通过回车同时查询最多
                    <strong className={styles.red}>10</strong>
                    个商家外部商品编码。
                  </div>
                )}
              >
                {[]}
              </Select>
            </FormItem>
          </div>
          <div>
            <FormItem label="商品名称">
              <Input
                placeholder="请输入商品标题中关键字"
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
                allowClear
              />
            </FormItem>
            <FormItem label="模版ID">
              <Input
                placeholder="请输入模版ID"
                value={templateId}
                onChange={(e) => setTemplateId(e.target.value)}
                allowClear
              />
            </FormItem>
          </div>
          <div>
            <FormItem label="商品状态">
              <Select
                placeholder="请选择商品状态"
                value={itemStatus}
                onChange={setItemStatus}
                allowClear
              >
                <Option value={ItemStatus.UP}>上架中</Option>
                <Option value={ItemStatus.DOWN}>已下架</Option>
              </Select>
            </FormItem>
          </div>
        </div>
        <Button
          className={styles.mr24}
          type="primary"
          onClick={handleSearch}
        >
          查询
        </Button>
        <Button
          type="primary"
          ghost
          onClick={handleReset}
        >
          重置
        </Button>
      </Card>
      <Card
        title={(
          <CardHeader
            title="商品列表"
            subTitle={total ? `（共${total}款）` : ''}
          />
        )}
      >
        <div className={styles.controller}>
          <a
            href="https://mms.pinduoduo.com/goods/category"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              type="primary"
              icon={<PlusOutlined />}
            >
              新增商品
            </Button>
          </a>
        </div>
        <div className={styles['table-wrapper']}>
          <Table
            columns={columns}
            dataSource={dataSource}
            rowKey="goods_id"
            rowSelection={{
              selectedRowKeys,
              onChange: setSelectedRowKeys,
            }}
            pagination={{
              total,
              current,
              pageSize,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `共${total}项`,
            }}
            onChange={handleSearch}
          />
          <div className={styles['table-operation']}>
            <span>{`已选：${selectedRowKeys.length}个商品`}</span>
            <Button
              type="link"
              disabled={!selectedRowKeys.length}
              onClick={() => updateGoodsStatus({ isOnsale: 1 })}
            >
              批量上架
            </Button>
            <Button
              type="link"
              disabled={!selectedRowKeys.length}
              onClick={() => updateGoodsStatus({ isOnsale: 0 })}
            >
              批量下架
            </Button>
          </div>
        </div>
        <Modal
          className={styles.modal}
          visible={modalVisible}
          title="标题优化"
          width={550}
          onCancel={() => setModalVisible(false)}
          onOk={optimizeTitle}
          okButtonProps={{
            disabled: newTitle.length > 60,
          }}
        >
          <Alert
            className={styles.alert}
            type="info"
            showIcon
            message="建议去除关键字“淘宝”“天猫”"
          />
          <Form
            className={styles.form}
            labelCol={{
              span: 3,
            }}
            wrapperCol={{
              span: 21,
            }}
            colon={false}
          >
            <FormItem
              label="优化前"
              help=""
            >
              <ItemInfo {...editingItem} />
            </FormItem>
            <FormItem
              label="优化后"
              validateStatus={newTitle.length > 60 ? 'error' : ''}
            >
              <div className={styles['textarea-wrapper']}>
                <TextArea
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                  placeholder="请输入商品标题"
                  autoSize
                />
                <span
                  className={cn([
                    styles.limit,
                    newTitle.length > 60 && styles.red,
                  ])}
                >
                  {`(${newTitle.length} / 60)`}
                </span>
              </div>
            </FormItem>
          </Form>
        </Modal>
      </Card>
    </div>
  );
};

export default ItemManagement;
