import history from '../../utils/history';
import ApiError from './ApiError';

const respHandler = (resp) => {
  const { status } = resp;
  switch (status) {
    case 200:
    case 204:
      return resp;
    default:
      return Promise.reject(
        new ApiError(resp.data.err_msg || '服务器错误', status),
      );
  }
};
const errostatusHandler = (error) => {
  const {
    response: { status, data = {} } = {},
  } = error;
  switch (status) {
    case 401:
    case 403:
      history.push('/401');
      break;
    default:
      break;
  }

  const {
    data: {
      err_msg: apiErrMsg,
    } = {},
  } = data;

  return Promise.reject(
    new ApiError((status === 400
      ? error.response.data.message
      : (apiErrMsg || error.message)
    ), status),
  );
};
export default [respHandler, errostatusHandler];
