/* eslint-disable */

/**
 * @param {RegExp} reg
 * @param {string} str
 */
function matchAll (reg, str) {
  if (!reg.test(str)) {
    return []
  }

  const {
    flags
  } = reg
  const allReg = new RegExp(reg, flags.indexOf('g') > -1 ? flags : `${flags}g`)
  const matches = []
  let match

  while ((match = allReg.exec(str))) {
    matches.push(match)
  }

  return matches
}

export default matchAll
