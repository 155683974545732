import React, { useState, useCallback } from 'react';
import { Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import cn from '../../../../utils/classNames';
import styles from './styles.module.css';

const {
  Item: MenuItem,
} = Menu;

const OptimizeItem = (props) => {
  const {
    title,
    item,
    desc,
    createTime,
    modifiedTime,
  } = props;

  const limitWordsStorage = JSON.parse(localStorage.getItem('limitWords') || '[]');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [current, setCurrent] = useState(limitWordsStorage.find((_item) => _item === item) ? '启用中' : '禁用中');
  const handleMenuClick = useCallback((e) => {
    const {
      item: {
        props: {
          children,
        },
      },
    } = e;

    setDropdownVisible(false);
    setCurrent(children);

    let limitWordsStorage = JSON.parse(localStorage.getItem('limitWords') || '[]');
    if (children === '启用中') {
      limitWordsStorage = limitWordsStorage.find((_item) => _item === item)
        ? limitWordsStorage : [...limitWordsStorage, item];
    } else if (limitWordsStorage) {
      const index = limitWordsStorage.findIndex((_item) => _item === item);
      if (index >= 0) {
        limitWordsStorage.splice(index, 1);
      }
    }
    localStorage.setItem('limitWords', JSON.stringify(limitWordsStorage));
  }, [item]);

  return (
    <div className={styles.item}>
      <div className={styles.body}>
        <div className={styles.left}>
          <div className={styles.title}>
            {title}
          </div>
          <div className={styles.desc}>
            描述：
            {desc}
          </div>
        </div>
        <div className={styles.right}>
          <Dropdown
            trigger="click"
            visible={dropdownVisible}
            onVisibleChange={setDropdownVisible}
            overlay={(
              <Menu onClick={handleMenuClick}>
                <MenuItem>
                  启用中
                </MenuItem>
                <MenuItem>
                  未启用
                </MenuItem>
              </Menu>
            )}
          >
            <div
              className={cn([
                styles['dropdown-trigger'],
                dropdownVisible && styles.active,
                current === '启用中' && styles.open,
              ])}
            >
              <span className={styles.point} />
              <span>{current}</span>
              <DownOutlined
                className={cn([
                  styles.icon,
                  dropdownVisible && styles.up,
                ])}
              />
            </div>
          </Dropdown>
        </div>
      </div>
      <div className={styles.footer}>
        <span>
          创建时间：
          {
            typeof createTime === 'string'
              ? createTime
              : moment(createTime).format('YYYY-MM-DD')
          }
        </span>
        <span>
          最新修改：
          {
            typeof modifiedTime === 'string'
              ? modifiedTime
              : moment(modifiedTime).format('YYYY-MM-DD')
          }
        </span>
      </div>
    </div>
  );
};

export default OptimizeItem;
