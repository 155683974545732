import { useState, createContext } from 'react';

export const TabKeys = {
  ITEM_SYNC: 'ITEM_SYNC',
  SYNC_HISTORY: 'SYNC_HISTORY',
};

const ItemSyncContext = createContext();
const {
  Provider,
} = ItemSyncContext;

function useItemSync() {
  const [activeKey, setActiveKey] = useState(TabKeys.ITEM_SYNC);
  const [initialValues, setInitialValues] = useState();

  const contextValue = {
    activeKey,
    setActiveKey,
    initialValues,
    setInitialValues,
  };

  return {
    activeKey,
    setActiveKey,
    initialValues,
    setInitialValues,
    contextValue,
  };
}

export default useItemSync;
export {
  ItemSyncContext,
  Provider,
};
