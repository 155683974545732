import React from 'react';
import { Card, Button } from 'antd';
import cn from '../../utils/classNames';
import styles from './styles.module.css';
import successImage from './assets/success.png';
import failedImage from './assets/failed.png';

export const Type = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

const Placeholder = (props) => {
  const {
    type = Type.SUCCESS,
    text,
    className,
    buttonProps,
  } = props;

  return (
    <Card className={cn([styles.placeholder, className])}>
      <img
        src={type === Type.SUCCESS ? successImage : failedImage}
        alt="PlaceholderImage"
        width={240}
      />
      {
        text && <div className={styles.text}>{text}</div>
      }
      <Button
        type="primary"
        {...buttonProps}
      >
        返回
      </Button>
    </Card>
  );
};

export default Placeholder;
