import {
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import { message } from 'antd';
import redcapApi from '../../../../apis/redcapApi';

const initialPages = {
  current: 1,
  pageSize: 10,
  total: 0,
  successTotal: 0,
  failedTotal: 0,
};

export const TaskStatus = {
  SUCCESS: 1,
  FAIL: 2,
  ALL: 0,
};

function useSearch() {
  const [inputValue, setInputValue] = useState('');
  const [queryStatus, setQueryStatus] = useState(TaskStatus.ALL);
  const [loading, setLoading] = useState(false);
  const [keywords, setKeywords] = useState(false);
  const [pages, setPages] = useState(initialPages);
  const [data, setData] = useState([]);

  const search = useCallback((params = {}) => {
    const {
      keywords = inputValue,
      taskStatus = queryStatus,
      current = 1,
      pageSize = pages.pageSize,
    } = params;

    setLoading(true);

    return redcapApi
      .fetchSyncHistory({
        goodsTitle: keywords,
        pageNo: current,
        pageSize,
        taskStatus: taskStatus || undefined,
      })
      .then((res) => {
        const {
          data: {
            page_no: current,
            page_size: pageSize,
            sync_success: successTotal,
            sync_fail: failedTotal,
            tasks,
          },
        } = res;

        batchedUpdates(() => {
          setKeywords(keywords);
          setInputValue(keywords);
          setQueryStatus(taskStatus);
          setData(tasks);
          setPages({
            current,
            pageSize,
            total: successTotal + failedTotal,
            successTotal,
            failedTotal,
          });
        });
      })
      .catch((err) => {
        message.error(err.message || '获取同步记录失败，请稍后再试');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [inputValue, pages, queryStatus]);

  const searchRef = useRef(search);

  useEffect(() => {
    searchRef.current = search;
  }, [search]);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current();
    }
  }, []);

  return {
    inputValue,
    setInputValue,
    loading,
    keywords,
    search,
    data,
    queryStatus,
    pages,
  };
}

export default useSearch;
